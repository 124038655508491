<template>
    <div class="infoMain">
        <alert-info :info="{ zysx: '加“*”是必填项，请按规定填写！' }"></alert-info>
        <div class="userinfo scrollbar">
            <el-form ref="depBasic" :model="depBasic" :rules="rules" label-width="100px" class="demo-ruleForm">
                <el-form-item label="部门名称" prop="Name">
                    <el-input v-model="depBasic.Name" onkeydown="if(event.keyCode==32) return false"></el-input>
                </el-form-item>
                <el-form-item label="部门职责" prop="Description">
                    <el-input v-model="depBasic.Description" type="textarea" :autosize="{ minRows: 3 }" :maxlength="200" onkeydown="if(event.keyCode==32) return false"></el-input>
                </el-form-item>
                <el-form-item class="buttonItem">
                    <sn-button size="small" :snButton="saveBtn" @handleChange="save">保存</sn-button>
                    <router-link :to="{ name: 'department' }">
                        <sn-button :snButton="cancleBtn" size="small">取消</sn-button>
                    </router-link>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>

import alertInfo from '@comp/alert_info';
import { mapActions } from 'vuex';
export default {
    name: 'departinfo',
    components: {
        alertInfo,
    },
    data() {
        // 输入部门名称 NAME
        let validateNAME = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入部门名称'));
            } else {
                if (value !== this.nameold) {
                    this.checkName(value).then(val => {
                        if (!val) {
                            callback(new Error('部门名称已存在'));
                        } else {
                            callback();
                        }
                    });
                } else {
                    callback();
                }
            }
        };
        return {
            rules: {
                Name: [
                    { required: true, validator: validateNAME, trigger: 'blur' },
                ],
            },
            saveBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'button',
                    operateType: 'save',
                    name: '保存',
                    round: true,
                    backColor: '#28ccd9',
                }]
            },
            cancleBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'button',
                    operateType: '',
                    name: '取消',
                    round: true,
                    backColor: '#ff9066',
                }]
            },
            newdoc: 'xinjian',
            strId: null,
            depBasic: {
                "Name": '', // 部门名称
                "Description": '', // 部门职责
            },
            nameold: null
        };
    },
    mounted() {
        let _this = this;
        _this.newdoc = _this.$route.params.newdoc;
        _this.strId = _this.$route.params.strId;
        if (_this.newdoc === 'bianji') {
            _this.getDepInfo(_this.strId);
        }
    },
    methods: {
        ...mapActions([
            'getDepData',
            'checkDepName',
            'saveDepData',
            'updateDepData'
        ]),
        async checkName(name) {
            let res = await this.checkDepName({
                name: name
            });
            return res;
        },
        // 获得部门详细信息
        async getDepInfo() {
            let res = await this.getDepData({
                id: this.strId
            });
            this.depBasic = res.JGXX[0];
            this.nameold = res.JGXX[0].Name;
        },
        save() {
            let _this = this;
            if (this.saveBtn.buttonData[0].disabled) return false;
            this.$refs.depBasic.validate((valid) => {
                if (valid) {
                    let msg = '此操作将添加到数据库, 是否继续?';
                    _this.$confirm(msg, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.saveBtn.buttonData[0].disabled = true;
                                let fwname = '';
                                let fwparams = {};
                                if (_this.newdoc === 'xinjian') {
                                    // 新建部门服务提交
                                    fwname = 'saveDepData';
                                }
                                if (_this.newdoc === 'bianji') {
                                    // 新建部门服务提交
                                    fwname = 'updateDepData';
                                }
                                let info = JSON.stringify(_this.depBasic);
                                fwparams = {
                                    info: info
                                };
                                _this.saveDep(fwname, fwparams, done);
                            } else {
                                done();
                            }
                        }
                    })
                        .then(() => {
                        })
                        .catch(() => {
                            _this.$message({ message: '已取消保存', type: 'info' });
                        });

                } else {
                    return false;
                }
            });
        },
        async saveDep(fwname, fwparams, done) {
            let res = await this[fwname](fwparams);
            if (res.IsSuccess) {
                done();
                this.$message({ message: '保存成功', type: 'success' });
                this.$router.push({
                    name: 'department'
                });
            } else {
                this.$message({ message: '保存失败', type: 'error' });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.header {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    color: #606266;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}
</style>